<template>
    <div>
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-tachometer-alt</v-icon> {{ (user.onboarded) ? 'Your Dashboard' : 'Getting Started' }}
            </v-toolbar-title>
        </v-toolbar>
        <v-row :class="(user.onboarded) ? 'sub-cont' : 'onboarding-container align-center'">
            <v-col cols="3" class="hidden-sm-and-down pr-0" v-if="user.onboarded">
                <LeftNav />
            </v-col>
            <v-col cols="12" :md="(user && user.onboarded) ? 9 : 12">
                <v-row dense>
                    <template v-if="user && user.onboarded">
                        <v-col cols="12" class="dashboard-alerts" v-if="!user.email_verified_at || !user.stripe_id || user.sub_ends_at || user.on_alert">
                            <v-alert dense type="error" class="mb-0 elevation-1 body-2 text-center text-md-left" @click="verifyEmail=true" v-if="!user.email_verified_at && !user.on_alert">
                                You haven't verified your email address. <br class="hidden-md-and-up" />
                                <v-btn small text outlined rounded >Verify Now</v-btn>
                            </v-alert>
                            <v-alert dense class="elevation-1 mb-0 body-2 text-center text-md-left" type="info" @click="$router.push('/app/subscribe')" v-if="user && user.onboarded && user.email_verified_at && !user.stripe_id && !user.on_alert && $route.name != 'subscribe'">
								Your free trial ends {{ $moment.utc(user.trial_ends_at).tz(user.timezone).fromNow() }}. <br class="hidden-md-and-up" />
								<v-btn small text outlined rounded >Subscribe Now</v-btn>
							</v-alert>
							<v-alert dense class="elevation-1 mb-0 body-2 text-center text-md-left" type="info" @click="$router.push('/app/subscription')" v-if="user && user.onboarded && user.sub_ends_at && !user.on_alert">
								Your subscription will end in {{ $moment.utc(user.sub_ends_at).tz(user.timezone).fromNow() }}. <br class="hidden-md-and-up" />
								<v-btn small text outlined rounded class="white--text font-weight-medium action-item">Resume Now</v-btn>
							</v-alert>
							<v-alert dense class="elevation-1 body-2 text-center mb-0 lh-12 font-weight-medium text-md-left" type="error" @click="$router.push('/app')" v-if="user && user.onboarded && user.on_alert">
								You&rsquo;re on alert! Please check in to let your contacts know you're okay.
							</v-alert>
                        </v-col>
                        <v-col v-if="user.once_checkin">
                            <v-alert dense type="info" icon="fa fa-clock" class="mb-0 elevation-1 lh-12 body-2 text-center text-md-left" v-if="user.once_checkin && !user.on_alert">
                                You have a 1-time check-in scheduled for {{ $moment.utc(user.once_checkin).tz(user.timezone).format('MMMM Do [at] h:mma') }}.
                                <br class="hidden-md-and-up" />
                                <v-btn small outlined text class="ml-md-2" rounded :loading="activating" @click.native="cancelOneTime()">
                                    <v-icon small class="mr-2">fa fa-times-circle</v-icon>
                                    Cancel
                                </v-btn>
                            </v-alert>
                        </v-col>
                        <v-col cols="12">
                            <Settings @cancelOneTime="cancelOneTime()" />
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col cols="12">
                            <Start />
                        </v-col>
                    </template>
                </v-row>
            </v-col>
        </v-row>
        <EmailVerify v-if="verifyEmail" @closed="verifyEmail=false"  />
        <v-dialog :value="true" v-if="showWelcome" overlay-opacity="0.6" overlay-color="#000" @input="closeWelcome()" max-width="520px">
            <v-card>
                <v-card-title class="py-2 d-block">
                    <h3 class="headline mb-0 primary--text text-center">Welcome!</h3>
                </v-card-title>
                <v-card-text class="px-0">
                    <v-divider />
                    <div class="px-5 mt-5 secondary--text text-center">
                        <p class="mb-1"><v-icon color="primary" size="30">fa fa-tachometer-alt</v-icon></p>
                        <p class="subtitle-1 lh-12">This is your dashboard. You can access all your<br class="hidden-sm-and-down" /> settings and tools from here.</p>
                        <p class="mb-1"><v-icon color="primary" size="34">fa fa-comment-sms</v-icon></p>
                        <p class="subtitle-1 lh-12">
                            Your first check-in will be sent at:
                            <br />
                            <span class="font-weight-medium">{{ $moment.utc(user.next_checkin).tz(user.timezone).format('h:mma [on] dddd, MMMM Do') }}</span>
                        </p>
                        <p class="mb-1"><v-icon color="primary" size="27">fa fa-list</v-icon></p>
                        <p class="subtitle-1 lh-12 mb-0">We&rsquo;ve created a to-do list of things<br> you may want to do next.</p>
                    </div>
                </v-card-text>
                <v-card-actions class="card-actions">
                    <v-spacer></v-spacer>
                    <v-btn class="px-10" color="primary" @click.native="closeWelcome()">Got it, let&rsquo;s go!</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog :value="true" v-if="showNoContactDialog" overlay-opacity="0.6" overlay-color="#000" persistent max-width="520px">
            <v-card>
                <v-card-title class="py-2 d-block">
                    <h3 class="headline mb-0 primary--text text-center">Check-Ins Disabled</h3>
                </v-card-title>
                <v-card-text class="px-0">
                    <v-divider />
                    <div class="px-5 mt-5 secondary--text text-center">
                        <p class="mb-2"><v-icon color="error" large>fa fa-exclamation-triangle</v-icon></p>
                        <p class="subtitle-1 font-weight-medium lh-12">You must have at least 1 emergency contact to receive check-ins messages.</p>
                        <v-btn class="px-5" color="primary" rounded @click.native="$router.push('/app/contacts')">Add a Contact Now</v-btn>
                    </div>
                </v-card-text>
                <v-card-actions class="card-actions">
                    <v-spacer></v-spacer>
                    <v-btn text outlined class="px-6" @click.native="showNoContactDialog=false">I&rsquo;ll do this later</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import { mapState } from 'vuex'
import LeftNav from '@/components/ui/LeftNav';

export default {
    name: 'UserDashboard',
    components: {
        LeftNav,
        Settings: () => import('@/components/user/dashboard/checkin/Settings'),
        // Items: () => import('@/components/user/dashboard/Items'),
		Start: () => import('@/components/onboarding/Start'),
        EmailVerify: () => import('@/views/auth/EmailVerify'),
		// Tasks: () => import('@/components/user/dashboard/Tasks'),
		// UserInfo: () => import('@/components/user/dashboard/UserInfo'),
    },
    computed: {
        ...mapState(['user']),
        showTasks(){
            if(!this.user.ux){
                return true;
            }
            let userUx = JSON.parse(this.user.ux);
            if(userUx.taskNotes == false || this.user.notes.length){
                if(this.user.image || userUx.taskUserImg == false){
                    return false;
                }
            }
            return true;
        },
        showWelcome(){
            return this.user.onboarded && this.user.onboarded != 2 && !this.hideWelcomeDialog;
        }
    },
    data: () => ({
        activating: false,
        verifyEmail: false,
        hideWelcomeDialog: false,
        showNoContactDialog: false,
    }),
    methods: {
        initData(){
            
        },
        cancelOneTime(){ 
            this.activating = true;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    once_cancel: true,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    let msg = 'One-time Check-in Canceled.';
                    this.$eventHub.$emit('snackBar', {
                        text: msg
                    });
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).finally(()=>{
                this.activating = false;
            });
        },
        closeWelcome(){
            this.hideWelcomeDialog = true;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    onboarded: 2,
                    _method: 'put'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('refreshUser');
                }
            }).finally(()=>{
                // this.showWelcome = false;
                if(!this.user.contacts.length){
                    this.showNoContactDialog = true;
                }
            });
        },
        // handleResize(){
        //     if(!this.$vuetify.breakpoint.mdAndUp){
        //         return;
        //     }
        //     let dash = document.getElementById('dash');
        // }
    },
    mounted(){
		this.initData();
	},
    watch: {

    }
}
</script>